import React, { useEffect, useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Navbar from "../../Components/Navbar";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Cards from "../../Components/SystemConfig/Cards";
import Loading from "../../Components/Loading";
import { toast } from "react-toastify";
const SystemConfig = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const { t, i18n } = useTranslation();

  const fetchDetails = async () => {
    let res = await axios.post(`${Config.apiUrl}/getsystemconfig`, "", {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

    return await res.data;
  };

  const { data, isLoading, isSuccess, isError, refetch, isRefetching,mutate } =
    useMutation(fetchDetails,{
        onSuccess:()=>console.log("Success"),
        onError:()=>console.log("Error")
    });
  
    useEffect(() => {
      const handler = setTimeout(() => {
        mutate();
      }, 500);
  
      return () => {
        clearTimeout(handler);
      };
    }, []);

  const postDataMutate = async (data) => {
    const body = new FormData();
    body.append("type", data.type);
    body.append("service", data.value);
    body.append("status",data.status)

    return await axios.post(`${Config.apiUrl}/editsystemconfig`, body, {
      headers: {
        authorization: "Bearer " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      mutate()
    }
  };

  const onError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingMutate, mutate: DataMutate } = useMutation(
    postDataMutate,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("System Config")}</Title>
              <Underline />
            </div>
          </div>
          {isLoadingMutate || (isLoading && <Loading />)}
          {!isLoading &&
            !isError &&
            isSuccess &&
            !isRefetching &&
            data.data.map((item, key) => (
              <div className="mt-8" key={key}>
                <Cards data={item} mutateData={DataMutate} />
              </div>
            ))}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

export default SystemConfig;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import Table from "../../Components/College/Table";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import NotFoundModel from "../../Components/NotFoundModel";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Config from "../../Config";
import Images from "../../Images";
import AddCollege from "../../Components/College/AddCollege";
import { toast } from "react-toastify";
import EditCollege from "../../Components/College/EditCollege";

export const Colleges = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState();
  const [addColleges, setAddColleges] = useState(false);
  const [apiData, setApiData] = useState();
  const [editData, setEditData] = useState(false);

  const fetchData = async () => {
    const body = new FormData();
    return await axios.post(`${Config.apiUrl}/getcollegelist`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onDataSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
    setApiData(res.data);
  };

  const onDataError = (data) => {};

  useEffect(() => {
    const handler = setTimeout(() => {
      DataMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  const {
    isLoading: isDataLoading,
    mutate: DataMutate,
    error,
    data,
  } = useMutation(fetchData, {
    onSuccess: onDataSuccess,
    onError: onDataError,
  });

  const onAddData = async (values) => {
    const body = new FormData();

    Object.keys(values).forEach((i) => body.append(i, values[i]));

    return await axios.post(`${Config.apiUrl}/addcollege`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddSuccess = (data) => {
    toast.success(data?.data?.msg || "Success");
    setApiData(data?.data);
    // DataMutate();
    setAddColleges(false);
  };

  const onAddError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occured");
    // DataMutate();
    setAddColleges(false);
  };

  const { isLoading: addCollegesLoading, mutate: addCollegesMutate } =
    useMutation(onAddData, {
      onSuccess: onAddSuccess,
      onError: onAddError,
    });

  const editDataPost = async (values) => {
    const body = new FormData();

    Object.keys(values).forEach((i) => body.append(i, values[i]));

    return await axios.post(`${Config.apiUrl}/editcollege`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditSuccess = (data) => {
    toast.success(data?.data?.msg || "Success");
    setApiData(data?.data);
    // DataMutate();
    setEditData(false);
  };

  const onEditError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occured");
    // DataMutate();
    setEditData(false);
  };

  const { isLoading: editIsLoading, mutate: editMutate } = useMutation(
    editDataPost,
    {
      onSuccess: onEditSuccess,
      onError: onEditError,
    }
  );
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>Colleges Categories</Title>
              <Underline />
            </div>
            <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setAddColleges(true)}
            >
              Add College
            </button>
          </div>
          {editData && (
            <EditCollege
              setEditData={setEditData}
              selectedData={selectedData}
              loading={editIsLoading}
              mutate={editMutate}
            />
          )}
          {addColleges && (
            <AddCollege
              setAddColleges={setAddColleges}
              loading={addCollegesLoading}
              mutate={addCollegesMutate}
            />
          )}
          <TableWrapper>
            {!error && !isDataLoading && data && (
              <Table
                ApiData={apiData?.data}
                setSelectedData={setSelectedData}
                userType={user.userType}
                selectedData={selectedData}
                setEditData={setEditData}
              />
            )}
            {isDataLoading && <Loading />}

            {(error || apiData?.msg == "No data found") && !isDataLoading && (
              <NotFoundModel />
            )}
          </TableWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};
const Title = tw.h1`text-xl w-full text-gray-800 whitespace-nowrap font-medium flex justift-center`;
const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;

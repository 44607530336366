import React from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import Loading from "../Loading";

const EditExpense = ({ setEditData, loading, mutate, selectedData }) => {
  const InitialValue = {
    name: selectedData.name || "",
  };
  const formValidation = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
  });

  const postData = (values) => {
    mutate({
      ...values,
      typeId: selectedData?.id,
      categoryId: selectedData?.categoryId,
    });
  };
  const expenseForm = useFormik({
    initialValues: InitialValue,
    validationSchema: formValidation,
    onSubmit: postData,
  });

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditData}>
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <Title>Edit Type</Title>
          <form onSubmit={expenseForm.handleSubmit}>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter Name of the expense"
                    onChange={expenseForm.handleChange}
                    value={expenseForm.values.name}
                  />

                  {expenseForm.touched.name && expenseForm.errors.name ? (
                    <p className="text-rose-600 text-sm">
                      {expenseForm.errors.name}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>
            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};

const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Wrapper = tw.div`px-1  space-y-4`;
const FieldWrapper = tw.div`w-full`;
const Input = tw.input`truncate border border-gray-300 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default EditExpense;

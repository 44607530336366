import React from "react";
import { useDropzone } from "react-dropzone";

const ExcelDropzone = ({ setExcelData }) => {
  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      console.log("No valid files dropped.");
    } else {
      setExcelData(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div className="mt-5">
      <h3 className="text-sm font-semibold mb-4 text-gray-500">
        Upload Tenant Details
      </h3>
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-10 mx-auto w-full max-w-xl cursor-pointer transition-colors 
          ${
            isDragActive
              ? "bg-blue-100 border-blue-400"
              : "bg-gray-100 border-gray-300"
          }`}
      >
        <input {...getInputProps()} className="text-center " />
        {isDragActive ? (
          <p className="text-blue-500">Drop the .csv file here...</p>
        ) : (
          <p className="text-gray-500 text-center">
            Click or Drag .csv file To Upload
          </p>
        )}
      </div>
    </div>
  );
};

export default ExcelDropzone;

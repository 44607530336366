import React, { useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";
import Navbar from "../../Components/Navbar";
import tw from "tailwind-styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading";
import Table from "../../Components/ExtraCharges/Table";
import EditExtraChargs from "../../Components/ExtraCharges/EditExtraChargs";
import { AddExtraCharges } from "../../Components/Properties/AddExtraCharges";
import { useEffect } from "react";
import NotFoundModel from "../../Components/NotFoundModel";

const ExtraCharges = () => {
  const { clientId, propId } = useParams();
  const user = useSelector((state) => state.UserReducer.user);
  const [addExtraCharges, setAddExtraCharges] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [extraCharges, setExtraCharges] = useState(null);
  const [apiData, setApiData] = useState();
  const location = useLocation();

  const fetchExtraCharges = async () => {
    if (clientId) {
      const formData = new FormData();
      formData.append("clientId", clientId);
      formData.append("propId", propId);
      return axios.post(`${Config.apiUrl}/getextracharges`, formData, {
        headers: {
          authorization: "Bearer " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
    }
  };

  const SuccessData = (data) => {
    setApiData(data.data);
  };
  const ErrorData = (data) => {};

  console.log(apiData);

  const {
    isLoading: isLoading,
    mutate: MutateMainData,
    data,
    isSuccess,
  } = useMutation(fetchExtraCharges, {
    onSuccess: SuccessData,
    onError: ErrorData,
  });

  console.log(data);
  useEffect(() => {
    
    MutateMainData();

  }, []);
  const EXTRA_CHARGES_TYPE = [
    "Please Select Charges Type",
    "Electricity",
    "Registration",
    "Tech Charges",
    "Maintenance",
    "Others",
  ];

  const AddChargesPost = async (values) => {
    values = Object.assign({ clientId: clientId }, values);
    values = Object.assign(
      { name: EXTRA_CHARGES_TYPE[values.chargesType] },
      values
    );

    const formData = new FormData();

    const keys = Object.keys(values);
    console.log(values["linkedProp"]);
    keys.map((i) => {
      if (i !== "linkedProp") formData.append(i, values[i]);
      else formData.append(i, JSON.stringify(values[i]));
    });

    return await axios.post(`${Config.apiUrl}/addextracharges`, formData, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const AddChargesSuccess = (data) => {
    if (data?.data?.status === "OK") {
      toast.success(data?.data?.msg || "Success");
    }
    setApiData(data.data);
    setExtraCharges(false);
    setSelectedData("");
  };

  const AddChargesError = (data) => {
    toast.error(data?.response?.data?.msg);
    setExtraCharges(false);
    setSelectedData("");
  };

  const { isLoading: AddChargesLoading, mutate: AddChargesMutate } =
    useMutation(AddChargesPost, {
      onSuccess: AddChargesSuccess,
      onError: AddChargesError,
    });
  const LinkUnlinkCharges = async (item) => {
    const formData = new FormData();
    formData.append("clientId", item.clientId);
    formData.append("propId", item.propId);
    formData.append("chargeId", item.id);
    formData.append("name", item.name);

    return await axios.post(`${Config.apiUrl}/removeextracharge`, formData, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const { isLoading: LinkLoading, mutate: LinkMutate } = useMutation(
    LinkUnlinkCharges,
    {
      onSuccess: (data) => {
        toast.success(data?.data?.msg || "Success");
        MutateMainData()
      },
      onError: (data) =>
        toast.error(data?.response?.data?.msg || "Error Occured"),
    }
  );

  const EditChargesFunction=async(data)=>{
    const body = new FormData();
    const {charges,repetation}=data
    body.append("id", selectedData.id);
    body.append("amount", charges);
    body.append("rep",repetation)
    body.append("clientId", clientId);
    body.append("propId", propId);

    return await axios
      .post(`${Config.apiUrl}/editextracharge`, body, {
        headers: {
          authorization: "Bearer " + user.token,
          "Content-Type": "multipart/form-data",
        },
      })
  }

  const EditChargesSuccess=(data)=>{
    console.log("Hogyaaa")
    if (data?.data?.status === "OK") {
      toast.success(data?.data?.msg || "Success");
    }
    setApiData(data.data);
    setAddExtraCharges(false);
    setSelectedData("");
  }

  const EditChargesError=(data)=>{
    toast.error(data?.response?.data?.msg);
    setAddExtraCharges(false);
    setSelectedData("");
  }

  const {isLoading:EditChargesLoading,mutate:EditMutateCharges}=useMutation(
    EditChargesFunction,
    {
      onSuccess:EditChargesSuccess,
      onError:EditChargesError
    }
  )

  console.log(apiData)

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {"Extra Charges "}
                <span className="text-primary-color">
                  ( {location?.state?.name} )
                </span>
                <Underline />
              </Title>
            </div>
            <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setExtraCharges(true)}
            >
              Add Charges
            </button>
          </div>

          {addExtraCharges && (
            <EditExtraChargs
              selectedData={selectedData}
              addExtraCharges={addExtraCharges}
              setAddExtraCharges={setAddExtraCharges}
              loadingData={EditChargesLoading}
              mutationData={EditMutateCharges}
            />
          )}

          {extraCharges && (
            <AddExtraCharges
              setAddCharges={setExtraCharges}
              data={location?.state?.apiData}
              AddChargesMutate={AddChargesMutate}
              AddChargesLoading={AddChargesLoading}
            />
          )}
          <TableWrapper>
            {isLoading && <Loading />}
            {isSuccess && !isLoading && apiData?.msg !== "No data found" && (
              <Table
                ApiData={apiData.data}
                userType={user.userType}
                setAddExtraCharges={setAddExtraCharges}
                setSelectedData={setSelectedData}
                LinkMutate={LinkMutate}
              />
            )}
            {(apiData?.msg == "No data found") && !isLoading && (
              <NotFoundModel />
            )}
          </TableWrapper>
          
        </BoxContainer>
      </Bg>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

export default ExtraCharges;

import React, { useEffect, useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import ExcelDropzone from "./ExcelDropzone";
import { SubmitBtn } from "../Styles/InputStyles";
import Loading from "../Loading";

const AddExcelFile = ({
  selectedData,
  setViewExcelFile,
  AddExcelFileMutate,
  AddExcelFileLoading,
}) => {
  const [excelData, setExcelData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (excelData) {
      let isError = false;
      excelData.map((i) => {
        if (i.type !== "text/csv") {
          setError("Please enter correct file format (.csv)");
          isError = true;
          setExcelData(undefined);
          return;
        }
      });
      if (!isError) {
        setError(false);
      }
    }
  }, [error, excelData]);

  const submitExcel = async () => {
    AddExcelFileMutate(excelData);
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewExcelFile}>
      {AddExcelFileLoading ? (
        <Loading />
      ) : (
        <>
          <Title className="text-center">Tenant List</Title>
          <h2 className="text-sm md:text-lg lg:text-lg text-gray-600 font-medium mt-3">
            CSV File
          </h2>
          <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">
            Please make sure to provide correct csv file format
          </p>
          <div className="bg-gray-200 p-3 rounded">
            <h3 className="text-sm font-semibold mb-2 text-gray-600">
              Download sample Dataset
            </h3>
            <div className="flex">
              <div className="text-sm text-gray-600">
                You can download the attached example and use them as a starting
                point for your own file
              </div>

              <SubmitBtn
                className="bg-white rounded p-2 hover:bg-gray-300 text-gray-600 hover:text-white"
                onClick={() => {
                  let a = document.createElement("a");
                  a.setAttribute("href", "/TenantRecords.csv");
                  a.setAttribute("download", "Tenant Records");
                  a.click();
                }}
              >
                {/* <a
              href="/TenantRecords.csv"
              className="text-sm text-gray-600"
              download
            >
              Download
            </a> */}
                Download
              </SubmitBtn>
            </div>
          </div>
          <ExcelDropzone setExcelData={setExcelData} />
          {excelData && (
            <div className="text-green-400 mt-2">
              {excelData[0].path} Uploaded Successfully.
            </div>
          )}
          {error && <div className="text-rose-500">{error}</div>}
          <BtnWrapper
            onClick={() => {
              submitExcel();
            }}
          >
            <SubmitBtn>Submit</SubmitBtn>
          </BtnWrapper>
        </>
      )}
    </Model>
  );
};

const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;

export default AddExcelFile;

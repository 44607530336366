import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  Label,
  SubmitBtn,
} from "../Styles/InputStyles";
import Model from "../Model";
import DropzoneImageUpload from "../Properties/DropzoneImageUpload";
import { toast } from "react-toastify";

const UploadLogo = ({
  uploadLogoMutate,
  uploadLogoLoading,
  setUploadLogo,
  selectedData,
}) => {
  const name = (name) => {
    if (!name) return "";
    const lowerCaseName = name.trim().toLowerCase();
    return lowerCaseName.length > 10
      ? `${lowerCaseName.slice(0, 10)}...`
      : lowerCaseName;
  };

  const InitialValues = {
    name: selectedData?.name,
    mobile: selectedData?.mobile,
    status: selectedData?.status,
    id: selectedData?.id,
  };

  const SubmitHandler = (values) => {
    if (!values.ownerLogoImage && !selectedData?.logoPath) {
      toast.error("Please upload the Owner Logo.");
      return;
    }
    uploadLogoMutate(values);
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setUploadLogo}>
      <Title>Upload Logo</Title>

      {uploadLogoLoading && <Loading />}

      {!uploadLogoLoading && (
        <Formik
          initialValues={InitialValues}
          onSubmit={SubmitHandler}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Wrapper>
                <InputGroup>
                  <Label htmlFor="logo">Upload Logo</Label>
                  <div>
                    <DropzoneImageUpload
                      setUploadFile={(file) => (values.ownerLogoImage = file)}
                      componentFor="logo"
                    />
                  </div>
                </InputGroup>

                {selectedData?.logoPath && (
                  <InputGroup>
                    <div className="flex items-center mt-[-15px]">
                      <svg
                        className="h-4 w-4 text-primary-color"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        />
                      </svg>
                      <a
                        href={selectedData.logoPath}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline text-primary-color ml-1 text-sm"
                      >
                        {name(selectedData?.name)}/logo.png
                      </a>
                    </div>
                  </InputGroup>
                )}
              </Wrapper>
              <BtnWrapper>
                <SubmitBtn type="submit">Upload</SubmitBtn>
              </BtnWrapper>
            </Form>
          )}
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;
const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;
export default UploadLogo;

import React, { useState, useEffect } from "react";

const Cards = ({ data, mutateData }) => {
  const [value, setValue] = useState("");
  const TYPE = ["", "Aadhaar Upload", "Aadhaar Number", "Pan Card"];

  useEffect(() => {
    if (data?.services?.length) {
      const defaultService = data.services.find((i) => i.status === 2);
      if (defaultService) {
        setValue(`${defaultService.name},${defaultService.status}`);
      } else {
        const firstService = data.services[0];
        if (firstService) {
          setValue(`${firstService.name},${firstService.status}`);
        }
      }
    }
  }, [data.services]);

  if (!value) return <div>Loading...</div>;

  return (
    <div className="border rounded-lg shadow-md p-6 bg-white">
      <div className="grid grid-cols-3 gap-4">
        {/* Type Section */}
        <div>
          <h2 className="text-2xl font-semibold text-blue-600">
            {TYPE[data.Type] || "Unknown Type"}
          </h2>
        </div>

        <div>
          <select
            onChange={(e) => {
              const selectedValue = e.target.value;
              setValue(selectedValue);
              const [selectedName, selectedStatus] = selectedValue.split(",");
              mutateData({
                value: selectedName,
                type: data?.Type,
                status: selectedStatus,
              });
            }}
            value={value}
            className="border border-gray-300 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
          >
            {data.services.map((item, key) => (
              <option key={key} value={`${item.name},${item.status}`}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center justify-center">
          <div className="text-sm text-gray-500">
            <span className="font-medium text-gray-800">Last Updated:</span>{" "}
            {new Date(data.updatedAt).toLocaleDateString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;

import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  // console.log("ashihs" + JSON.stringify(selectedData));

  const getGender = (gender) => {
    if (gender === 1) {
      return "Male";
    } else {
      return "Female";
    }
  };

  const getOccupation = (occupation) => {
    if (occupation == 1) {
      return "Student";
    } else {
      return "Professional";
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title>
          View Details
        </Title>

        <Column>
          <FirstColumn>
            <SubHeading>Name</SubHeading>
            <Paragraph>{selectedData?.name || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Phone Number</SubHeading>
            <Paragraph>{selectedData?.mobile || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />

        <Column>
          <FirstColumn>
            <SubHeading>Gender</SubHeading>
            <Paragraph>{getGender(selectedData?.gender) || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Occupation</SubHeading>
            <Paragraph>{selectedData?.occupation || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />

        <Column>
          <FirstColumn>
            <SubHeading>Date of Birth</SubHeading>
            <Paragraph>{selectedData?.dob || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Address</SubHeading>
            <Paragraph>{selectedData?.address || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>Father's Name</SubHeading>
            <Paragraph>{selectedData?.fatherName || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Property Name</SubHeading>
            <Paragraph>{selectedData?.propName || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>Email</SubHeading>
            <Paragraph>{selectedData?.email || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Alternate Number</SubHeading>
            <Paragraph>{selectedData?.alternateMobile || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />

        <Column>
        <FirstColumn>
            <SubHeading>Updated At</SubHeading>
            <Paragraph>{moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A") || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Created At</SubHeading>
            <Paragraph>{moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A") || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        

        {/* <SingleItem name={"Name"} value={selectedData?.name} />
        <SingleItem name={"Phone Number"} value={selectedData?.mobile} />
        <SingleItem
          name={"Gender"}
          value={getGender(selectedData?.gender) || "N/A"}
        />
        <SingleItem
          name={"Occupation"}
          value={getOccupation(selectedData?.occupation) || "N/A"}
        />
        <SingleItem
          name={"Date of Birth"}
          value={moment(selectedData?.dob).format("DD-MM-YYYY") || "N/A"}
        />
        <SingleItem
          name={"Father's Name"}
          value={selectedData?.fatherName || "N/A"}
        />
        <SingleItem
          name={"Property Name"}
          value={selectedData?.propName || "N/A"}
        />
        <SingleItem name={"Email"} value={selectedData?.email || "N/A"} />
        <SingleItem
          name={"Alternate Mobile"}
          value={selectedData?.alternateMobile || "N/A"}
        />
        <SingleItem name={"Address"} value={selectedData?.address || "N/A"} />
        <SingleItem
          name={"Created At"}
          value={
            moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A") ||
            "N/A"
          }
        />
        <SingleItem
          name={"Updated At"}
          value={
            moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A") ||
            "N/A"
          }
        /> */}
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`;
const FirstColumn = tw.div``;
const Column = tw.div`grid grid-cols-2 my-3 gap-2`;
const SubHeading = tw.h2`text-xs text-blue-500 font-[600] mb-1`;
const Paragraph = tw.p`text-sm text-gray-900 font-semibold`;

export default ViewDetailModel;

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const RoomDetailForm = ({ selectedBed, postTenantData,selectedBedRoomData,selectedBedData}) => {
  const toastSelectBed = () =>
    toast.warn("Please Select Bed Id", {
      hideProgressBar: false,
    });
  const location = useLocation();

  const addTenantSchema = Yup.object().shape({
    floor: Yup.string().max(10).required("Floor Required"),
    rent: Yup.number().required("Rent required"),
    rentalCycle: Yup.string().required("Rental Cycle is required"),
    security: Yup.number().required("Security is Required"),
    moveInDate: Yup.string().required("Start date is required"),
    agreementPeriod: Yup.string().required("Agreement Period Required"),
    noticePeriod: Yup.string()
      .required("Notice period Required")
      .test(
        "noticePeriod-vs-agreementPeriod",
        "Notice period cannot be more than the agreement period",
        function (value) {
          const { agreementPeriod } = this.parent;
          return parseInt(value) <= parseInt(agreementPeriod);
        }
      ),
    lockInPeriod: Yup.string().required("Lock in Period Required")
  });
  

  const InitialValue = {
    //maintenance: "",
    floor: location?.state?.floorNumber || "",
    rent: location?.state?.rent || "",
    security: selectedBedRoomData?.security || "",
    rentalCycle: "",
    noticePeriod: selectedBedRoomData?.noticePeriod || "",
    lockInPeriod: selectedBedRoomData?.lockInPeriod || "",
    agreementPeriod: selectedBedRoomData?.agreementPeriod || "",
    //agreementStartDate: "",
    moveInDate: "",
  };

  const formik = useFormik({
    initialValues: InitialValue,
    enableReinitialize:false,
    validationSchema: addTenantSchema,
    onSubmit: (values, { resetForm }) => {
      selectedBed ? postTenantData(values) : toastSelectBed();
    },
  });
  const months = [
    "Select a Value",
    "1",
    "1.5",
    "2",
    "2.5",
    "3",
    "3.5",
    "4",
    "4.5",
    "5",
    "5.5",
    "6",
    "6.5",
    "7",
    "7.5",
    "8",
    "8.5",
    "9",
    "9.5",
    "10",
    "10.5",
    "11",
  ];
  
  return (
    <form onSubmit={formik.handleSubmit} id="room-detail-form" className="flex items-end flex-wrap gap-3 w-full mt-8">
      <div className="w-full flex gap-5 max-sm:flex-wrap ">
      {/* FLOOR */}
        <div className="flex-row justify-start items-center w-full space-y-2">
          <lable htmlFor="floor" className="   text-gray-500">
            Floor{" "}
          </lable>
          <input
            type="text"
            name="floor"
            id="floor"
            className="border border-gray-400 rounded w-full pl-2  "
            placeholder="Enter Floor"
            onChange={formik.handleChange}
            value={formik.values.floor}
            disabled={location.state.floorNumber?true:false}
          />
          <div className="text-rose-500">
            {formik.touched.floor && formik.errors.floor}
          </div>
        </div>
        {/* RENT */}
        <div className="flex-row justify-start items-center w-full space-y-2">
          <lable htmlFor="rent" className="   text-gray-500">
            Rent ₹{" "}
          </lable>
          <input
            type="text"
            name="rent"
            value={formik.values.rent}
            //disabled={location?.state?.rent?true:false}
            id="rent"
            className="border border-gray-400 rounded w-full  pl-2 "
            placeholder="Enter Rent"
            onChange={(e) => {
              const { value } = e.target;
              if (/^\d{0,10}$/.test(value)) {
                formik.handleChange(e);
              }
            }}          />
          <div className="text-rose-500">
            {formik.touched.rent && formik.errors.rent}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-5 max-sm:flex-wrap">
      {/* SECURITY */}
        <div className="flex-row justify-start items-center w-full space-y-2">
          <lable htmlFor="security" className="   text-gray-500">
            Security ₹{" "}
          </lable>
          <input
            type="text"
            name="security"
            id="security"
            className="border border-gray-400 rounded w-full pl-2  "
            placeholder="Enter Security"
            onChange={(e) => {
              const { value } = e.target;
              if (/^\d{0,10}$/.test(value)) {
                formik.handleChange(e);
              }
            }}            value={formik.values.security}
          />
          <div className="text-rose-500">
            {formik.touched.security && formik.errors.security}
          </div>
        </div>
        {/* RENTAL CYCLE */}
        <div className="flex-row justify-start items-center w-full relative space-y-2">
          <lable htmlFor="rentalCycle" className="text-gray-500">
            Rental Cycle
          </lable>
          <input
            type="date"
            name="rentalCycle"
            id="rentalCycle"
            className="border border-gray-400 rounded w-full pl-2  "
            placeholder="Enter Rental cycle"
            onChange={formik.handleChange}
            value={formik.values.rentalCycle}
          />
          <div className="text-rose-500">
            {formik.touched.rentalCycle && formik.errors.rentalCycle}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-5 max-sm:flex-wrap">
        {/* MOVE IN DATE */}
        <div className="flex-row justify-start items-center w-full relative space-y-2">
          <lable htmlFor="moveInDate" className="text-gray-500">
            Move In Date
          </lable>
          <input
            type="date"
            name="moveInDate"
            id="moveInDate"
            className="border border-gray-400 rounded w-full  pl-2 "
            placeholder="Enter Move In Date"
            onChange={formik.handleChange}
            min={selectedBedRoomData?.moveOutDate?.slice(0,10)}            
          />
          <div className="text-rose-500">
            {formik.touched.moveInDate && formik.errors.moveInDate}
          </div>
        </div>

        {/* AGREEMENT PERIOD */}
        <div className="flex-row justify-start items-center w-full space-y-2">
          <lable
            htmlFor="agreementPeriod"
            className="   text-gray-500"
          >
            Agreement Period
          </lable>
          <select
            className="   border-gray-400 rounded border w-full pl-2"
            name="agreementPeriod"
            onChange={formik.handleChange}
            value={formik.values.agreementPeriod}
          >
            {months.map((item, key) => (
              <option key={key} value={item}>{item} {key===1? "Month": "Months"}</option>
            ))}
          </select>
          <div className="text-rose-500">
            {formik.touched.agreementPeriod && formik.errors.agreementPeriod}
          </div>
        </div>
      </div>

      
      <div className="w-full flex gap-5 max-sm:flex-wrap">
        {/* NOTICE PERIOD */}

        <div className="flex-row justify-start items-center w-full space-y-2">
          <lable htmlFor="noticePeriod" className="   text-gray-500">
            Notice Period
          </lable>
          <select
            className="   border-gray-400 rounded border w-full pl-2"
            onChange={formik.handleChange}
            name="noticePeriod"
            value={formik.values.noticePeriod}
          >
            {months.map((item, key) => (
              <option key={key} value={item}>{item} {key===1? "Month": "Months"}</option>
            ))}
          </select>
          <div className="text-rose-500">
            {formik.touched.noticePeriod && formik.errors.noticePeriod}
          </div>
        </div>
        {/* LOCKIN PERIOD */}
        <div className="flex-row justify-start items-center w-full space-y-2">
          <lable htmlFor="lockInPeriod" className="   text-gray-500">
            Lock In Period
          </lable>
          <select
            className="border-gray-400 rounded border w-full pl-2"
            onChange={formik.handleChange}
            name="lockInPeriod"
            value={formik.values.lockInPeriod}
          >
            {months.map((item, key) => (
              <option key={key} value={item}>{item} {key===1? "Month": "Months"}</option>
            ))}
          </select>
          <div className="text-rose-500">
            {formik.touched.lockInPeriod && formik.errors.lockInPeriod}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-5 max-sm:flex-wrap"></div>
      <div className="w-full flex gap-5 max-sm:flex-wrap">
      </div>
    </form>
  );
};

export default RoomDetailForm;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import Table from "../../Components/ExpenseType/Table";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import NotFoundModel from "../../Components/NotFoundModel";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Config from "../../Config";
import Images from "../../Images";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import AddBankComp from "../../Components/AddBank/AddBank";
import LinkBankAccount from "../../Components/AddBank/LinkBankAccount";

function AddBankPage() {
  const location = useLocation();
  const user = useSelector((state) => state.UserReducer.user);

  const { clientId } = useParams();
  const [apiData, setApiData] = useState();
  const [addBankComp, setAddBankComp] = useState();
  const [selectedData, setSelectedData] = useState();
  const [linkBank, setLinkBank] = useState();

  const fetchData = async () => {
    const body = new FormData();
    body.append("clientId", clientId);
    return await axios.post(`${Config.apiUrl}/getbankaccount`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };


  const onDataError = (data) => {  };
 
  
  const onDataSuccess = (res) => {
    setApiData(res.data)
    if (res.data.status == "NOK" || res.data.data == false) error = true;

    setApiData(res.data);
  };


  useEffect(() => {
    const handler = setTimeout(() => {
      DataMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  const {
    isLoading: isDataLoading,
    mutate: DataMutate,
    error,
    data,
  } = useMutation(fetchData, {
    onSuccess: onDataSuccess,
    onError: onDataError,
  });

  const onAddBankData = async (values) => {
    const body = new FormData();

    Object.keys(values).forEach((i) => body.append(i, values[i]));
    body.append("clientId", clientId);
    body.append("type", 1);
    return await axios.post(`${Config.apiUrl}/addbankaccount`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddBankSuccess = (data) => {
    toast.success(data?.data?.msg || "Success");
    setApiData(data?.data);
    // DataMutate();
    setAddBankComp(false);
  };

  const onAddBankError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occured");

    // DataMutate();
    setAddBankComp(false);
  };

  const { isLoading: addBankLoading, mutate: addBankMutate } = useMutation(
    onAddBankData,
    {
      onSuccess: onAddBankSuccess,
      onError: onAddBankError,
    }
  );

  const onAddDataUPI = async (values) => {
    const body = new FormData();

    Object.keys(values).forEach((i) => body.append(i, values[i]));
    body.append("clientId", clientId);
    body.append("type", 2);
    return await axios.post(`${Config.apiUrl}/addbankaccount`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddSuccessUPI = (data) => {
    toast.success(data?.data?.msg || "Success");
    setApiData(data.data);
    // DataMutate();
    setAddBankComp(false);
  };

  const onAddErrorUPI = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occured");

    // DataMutate();
    setAddBankComp(false);
  };

  

  const { isLoading: addUPILoading, mutate: addUPIMutate } = useMutation(
    onAddDataUPI,
    {
      onSuccess: onAddSuccessUPI,
      onError: onAddErrorUPI,
    }
  );
  
  const LinkBankFunction = async (checkData) => {
    const formData = new FormData();
    formData.append("clientId", clientId);

    const linkedBank = [];
    const unlinkedBank = [];
    checkData.forEach((isChecked, propId) => {
      const BankInfo = { bankId: selectedData.id, propId: propId };
      isChecked ? linkedBank.push(BankInfo) : unlinkedBank.push(BankInfo);
    });

    formData.append("linkBank", JSON.stringify(linkedBank));
    formData.append("unlinkBank", JSON.stringify(unlinkedBank));

    return await axios.post(`${Config.apiUrl}/linkunlinkbanks`, formData, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const LinkBankOnSuccess = (data) => {
    if (data?.data?.status === "OK") {
      toast.success(data?.data?.msg || "Success");
      
      setApiData(data.data)
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setLinkBank(false);
    setSelectedData("");
  };

  const LinkBankOnError = (data) => {
    setLinkBank(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: LinkBankLoading, mutate: LinkBankMutate } = useMutation(
    LinkBankFunction,
    {
      onSuccess: LinkBankOnSuccess,
      onError: LinkBankOnError,
    }
  );


  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {"Bank Detail's"}
                {"\u00A0"}
                <span className="text-primary-color">
                  ( {location?.state?.name} )
                </span>
              </Title>
              <Underline />
            </div>
            <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setAddBankComp(true)}
            >
              Add bank
            </button>
          </div>

          {linkBank && (
            <LinkBankAccount
              setLinkBank={setLinkBank}
              isLoading={LinkBankLoading}
              mutate={LinkBankMutate}
              propertyList={apiData?.properties}
              selectedData={selectedData}

            />
          )}

          {addBankComp && (
            <AddBankComp
              setAddBank={setAddBankComp}
              mutateBank={addBankMutate}
              isLoadingBank={addBankLoading}
              mutateUPI={addUPIMutate}
              isLoadingUPI={addUPILoading}
            />
          )}

          <TableWrapper>
            { (!isDataLoading || !error) && 
               (
              <Table
                ApiData={apiData?.data}
                setSelectedData={setSelectedData}
                userType={user.userType}
                setLinkBank={setLinkBank}
              />
            )}


            {isDataLoading && <Loading />}

            {(apiData?.msg == "No data found") && !isDataLoading && (
              <NotFoundModel />
            )}
          </TableWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
}
const Title = tw.h1`text-xl w-full text-gray-800 whitespace-nowrap font-medium flex justift-center`;
const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;

export default AddBankPage;

import React from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../Loading";
import { TextField } from "@mui/material";

const EditStaff = ({
  editStaffMutate,
  editStaffLoading,
  setEditStaff,
  data,
  selectedData,
}) => {
  const SubmitHandler = (values) => {
    editStaffMutate(values);
  };
  const formik = useFormik({
    initialValues: {
      name: selectedData?.name || "",
      mobile: selectedData?.mobile || "",
      role: selectedData?.role || "",
      gender: selectedData?.gender || "",
      salary: selectedData?.salary || "",
      status: selectedData?.status || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      mobile: Yup.string()
        .matches(/^\d{10}$/, "Enter Valid number")
        .required("Mobile number is required"),
      role: Yup.string().required("Role is required"),
      gender: Yup.string().required("Gender is required"),
      salary: Yup.number()
        .required("Enter salary")
        .min(0, "Salary Cannot be less than 0")
        .typeError("Salary must be a number"),
      status: Yup.number()
        .required("Select Status")
        .min(1, "Status can be active or inactive"),
    }),
    onSubmit: SubmitHandler,
    enableReinitialize: true,
  });
  return (
    <Model width="w-11/12 max-w-lg" setOpenModel={setEditStaff}>
      {editStaffLoading ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Edit Staff</Title>
          <form onSubmit={formik.handleSubmit}>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="name">Name</Label>
                  <FieldWrapper>
                    <TextField
                      size="small"
                      fullWidth
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      className="truncate border border-gray-300 border-m rounded-sm"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      //onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <p className="text-rose-600 text-sm ">
                        {formik.errors.name}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper>
                    <Select
                      name="status"
                      id="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      //onBlur={formik.handleBlur}
                      className="w-full h-full pl-2"
                    >
                      <MenuItem value="1">Active</MenuItem>
                      <MenuItem value="2">Inactive</MenuItem>
                    </Select>
                    {formik.touched.status && formik.errors.status ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.status}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <CombineInputGroup className="pb-3">
                <InputGroup className="">
                  <Label htmlFor="mobile">Phone</Label>
                  <FieldWrapper className="bg-gray-100 text-black roudned-md border-black">
                    <input
                      size="small"
                      type="text"
                      name="mobile"
                      id="mobile"
                      autoComplete="off"
                      className="truncate border-black rounded-md w-full h-full p-3 "
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      //onBlur={formik.handleBlur}
                      disabled
                      inputProps={{ maxLength: 10 }}
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.mobile}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="role">Role</Label>
                  <FieldWrapper>
                    <Select
                      name="role"
                      id="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      //onBlur={formik.handleBlur}
                      className="w-full h-full pl-2"
                    >
                      <MenuItem value="1">Admin</MenuItem>
                      <MenuItem value="2">Warden</MenuItem>
                      <MenuItem value="3">Electrician</MenuItem>
                      <MenuItem value="4">Plumber</MenuItem>
                    </Select>
                    {formik.touched.role && formik.errors.role ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.role}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <CombineInputGroup className="pb-3">
                <InputGroup>
                  <Label htmlFor="salary">Salary ₹</Label>
                  <FieldWrapper>
                    <TextField
                      size="small"
                      type="text"
                      name="salary"
                      id="salary outlined-size-small"
                      autoComplete="off"
                      className="truncate "
                      variant="outlined"
                      value={formik.values.salary}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (/^\d{0,10}$/.test(value)) {
                          formik.handleChange(e);
                        }
                      }} //onBlur={formik.handleBlur}
                    />
                    {formik.touched.salary && formik.errors.salary ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.salary}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gender">Gender</Label>
                  <FieldWrapper>
                    <Select
                      name="gender"
                      id="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      //onBlur={formik.handleBlur}
                      className="w-full h-full pl-2"
                    >
                      <MenuItem value="1">Male</MenuItem>
                      <MenuItem value="2">Female</MenuItem>
                    </Select>
                    {formik.touched.gender && formik.errors.gender ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.gender}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Wrapper = tw.div`px-1  space-y-4`;
// const InputGroup = tw.div`w-full space-y-2`;
const FieldWrapper = tw.div`w-full`;
const CombineInputGroup = tw.div`flex space-x-7`;

export default EditStaff;

import React, { useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import { useSelector } from "react-redux";
import axios from "axios";
import Config from "../../Config";
import { useQuery } from "react-query";
import Loading from "../Loading";
import { SubmitBtn } from "../Styles/InputStyles";
import { toast } from "react-toastify";

const AddCharges = ({ setAddCharges, selectedData }) => {
  const [dataMap, setDataMap] = useState(new Map());
  const user = useSelector((state) => state.UserReducer.user);

  const fetchFunction = async () => {
    const formData = new FormData();
    formData.append("clientId", selectedData.id);

    return await axios
      .post(`${Config.apiUrl}/getextrachargesforclient `, formData, {
        headers: {
          authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((res) => res.data)
      .catch((err) => err);
  };

  const { data, isLoading, isSuccess } = useQuery({
    queryFn: () => fetchFunction(),
    queryKey: ["fetchCharges"],
  });

  const changeCharges = (propId, chargeId) => {
    console.log(chargeId);
    if (propId === 0) {
      setDataMap(new (setDataMap(dataMap.delete(chargeId)))());
      return;
    } else setDataMap(new Map(dataMap.set(chargeId, propId)));
  };

  const submitValues = async () => {
    const formData = new FormData();
    const dataArray = [];
    for (let [key, value] of dataMap) {
      if (parseInt(value) === 0) continue;
      else {
        dataArray.push({ ChargeId: key, PropId: value });
      }
    }
    if (dataArray.length > 0) {
      formData.append("clientId", selectedData.id);
      formData.append("newCharges", JSON.stringify(dataArray));
      await axios
        .post(`${Config.apiUrl}/addextrachargesforclient `, formData, {
          headers: {
            authorization: "Bearer" + " " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((res) => toast.success(res.data.msg))
        .catch((err) => toast.error(err.response.data.msg));
      setAddCharges(null);
    } else {
      setAddCharges(null);
      toast.warn("No Data was selected");
    }
  };

  console.log(selectedData);

  return (
    <Model width={`w-auto max-w-lg`} setOpenModel={setAddCharges}>
      <Title>Add Charges</Title>
      <div className="flex w-full">
        {isLoading && <Loading />}
        {!isLoading && !isSuccess && <div>Internal server Error</div>}
        {!isLoading && isSuccess && (
          <div className="grid grid-cols-4 gap-2 p-2 my-4 bg-gray-50 rounded-lg shadow-md w-full">
            <div className="font-semibold text-gray-600">Charges</div>
            <div className="font-semibold text-gray-600">Price</div>
            <div className="font-semibold text-gray-600">Repetition</div>
            <div className="font-semibold text-gray-600">Name</div>

            {data.extraCharges.map((i, key) =>
               (
                <React.Fragment key={key}>
                  <div className="bg-blue-100 text-blue-700 p-2 px-1 rounded-md shadow-sm border border-blue-300 whitespace-nowrap ">
                    {i.name}
                  </div>
                  <div className="bg-green-100 text-green-700 p-2 rounded-md shadow-sm border border-green-300">
                    ₹ {i.amount}
                  </div>
                  <div className="bg-gray-100 text-gray-700 p-2 rounded-md shadow-sm border border-gray-300">
                    {i.repetitionType === 1 ? "Once" : "Monthly"}
                  </div>
                  {/* <select
                    className={`border border-gray-300 p-2 rounded-md focus:ring focus:ring-blue-200 transition duration-300 ease-in-out ${
                      i.propId ? "border-primary-color" : ""
                    }`}
                    onChange={(e) => changeCharges(e.target.value, i.id)}
                  >
                    <option value="0">Select Property</option>
                    {data.properties.map((item, key) => (i.propId===null || i.propId !==item.propId) ?(
                      <option key={key} value={item.propId}>
                        {item.propName}
                      </option>
                    ):"")}
                  </select> */}
                  <div className="bg-slate-100 text-slate-700 p-2 rounded-md shadow-sm border border-slate-300 whitespace-nowrap">
                    {i.propName || "N/A"}
                  </div>
                  
                </React.Fragment>
              ) 
            )}
          </div>
        )}
      </div>
      <div className="flex  justify-between w-full ">
      <SubmitBtn onClick={() => submitValues()}>Submit</SubmitBtn>
        <SubmitBtn >Add Charges</SubmitBtn>
      </div>
    </Model>
  );
};
const Status = tw.div``;
const PropertyId = tw.div`bg-cyan-600 text-white text-xs  inline-block py-1 max-w-fit px-3 flex items-center justify-center rounded-full`;
const Active = tw.div`bg-emerald-600 text-white text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Enrolled = tw.div`bg-yellow-600 text-white text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Unverified = tw.div`bg-rose-600 text-white text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl text-gray-700 font-bold text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const Column = tw.div`grid grid-cols-2 my-3 gap-2`;
const FirstColumn = tw.div``;
const SecondColumn = tw.div``;
const SubHeading = tw.h2`text-xs text-blue-500 font-[600] mb-1`;
const Paragraph = tw.p`text-sm text-gray-900 font-semibold`;
const Verified = tw.div`bg-purple-600 text-white text-xs inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;

export default AddCharges;

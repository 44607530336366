import React from "react";
import tw from "tailwind-styled-components"; 
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {

  const getTenantGender = (tenantGender) => {
    if (tenantGender === 1) {
      return  'Male';
    } else if (tenantGender === 2) {
      return  'Female';
    } else {
      return  'Both';
    }
  };

  
  const getStatus = (status) => {
    if (status === 1) {
      return  <Pending>Pending</Pending>;
    } else if (status === 2) {
      return  <Inprogress>Assigned</Inprogress>;
    } else {
      return <Resolve>Resolved</Resolve>;
    }
  };
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title><MdViewHeadline className="mt-1" />View Details</Title>
{/* <SingleItem name={"ID"} value={selectedData?.id} />
<SingleItem name={"Client ID"} value={selectedData?.clientId} />
<SingleItem name={"Tenant ID"} value={selectedData?.tenantId} />
<SingleItem name={"Property ID"} value={selectedData?.propId} /> */}
<SingleItem name={"Tenant Name"} value={selectedData?.tenantName} />
<SingleItem name={"Client Name"} value={selectedData?.clientName} />
<SingleItem name={"Property Name"} value={selectedData?.propertyName} />
<SingleItem name={"Title"} value={selectedData?.title} />
<SingleItem name="Status" value={getStatus(selectedData?.status)} />
<SingleItem name="Gender" value={getTenantGender(selectedData?.tenantGender)} />
<SingleItem name={"Room Number"} value={selectedData?.roomId} />
<SingleItem name={"Floor"} value={selectedData?.floor} />
<SingleItem name={"Description"} value={selectedData?.description} />
<SingleItem name={"Assigned To"} value={selectedData?.assignedTo || "N/A"} />
<SingleItem name={"Created At"} value={moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A")} />
<SingleItem name={"Updated At"} value={moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A")} /> 
      </Wrapper>
    </Model> 
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded`;
const Inprogress = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const Resolve = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
export default ViewDetailModel;

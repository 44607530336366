import React, { useEffect, useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import { QueryClient, useQuery } from "react-query";
import Loading from "../Loading";
import { SubmitBtn } from "../Styles/InputStyles";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const CheckBox = ({ data, intial, checkOnchange, value}) => {
  const [isSelected, setIsSelected] = useState(intial);
  useEffect(() => {
    setIsSelected(intial);
  }, [intial]);
  return (
    <div className="flex items-center justify-between mb-4">
      <p className="text-gray-700 font-medium">{data}</p>
      <input
        type="checkbox"
        checked={isSelected}
        value={value}
        className="w-6 h-6 text-blue-600 bg-gray-200 rounded-md border-gray-300 focus:ring-blue-500 cursor-pointer"
        onClick={() => setIsSelected(!isSelected)}
        onChange={() => checkOnchange(value, !isSelected)}
      />
    </div>
  );
};

const LinkCharges = ({ setLinkCharges, selectedData,propertyList,LinkExtraChargesMutate,LinkExtraChargesLoading }) => {
  
  const [checkData, setCheckData] = useState(new Map()); // Use an object for state

  // Post request to link charges
  const postData = async () => {
    LinkExtraChargesMutate(checkData)
  };



  // Handle checkbox state change
  const handleCheckboxChange = (value, checked) => {
    setCheckData(new Map(checkData.set(value, checked)));
  };
  
  return (

    <Model setOpenModel={setLinkCharges} width={`w-11/12 max-w-lg`}>
        {LinkExtraChargesLoading? <Loading/>:(
      <Wrapper>
          <StyledWrapper>
            <Title>Link Charges</Title>
            <div className="flex-col mb-4">
              {propertyList.map((item, key) => (
                <div key={key}>
                  
                  <CheckBox
                    data={item.propName}
                    intial={selectedData?.properties?.filter(i=>i.propId===item.propId).length>0}
                    checkOnchange={handleCheckboxChange}
                    value={item.propId}
                  />
                </div>
              ))}
            </div>
            <div className="flex w-full justify-center items-center">
              <SubmitBtn onClick={() => postData()}>Submit</SubmitBtn>
            </div>
          </StyledWrapper>
      </Wrapper>
        )}
    </Model>
  );
};

export default LinkCharges;

const Wrapper = tw.div`px-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const StyledWrapper = tw.div`px-8 py-6 bg-white `;

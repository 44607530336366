import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import { toast } from "react-toastify";

const EditBeds = ({
  editBedsMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const InitialValues = {
    name: selectedData?.tenantName,
    mobile: selectedData?.tenantMobile,
    status: selectedData?.status,
    email: selectedData?.email,
    id: selectedData?.bedId,
    gender: selectedData?.tenantGender,
    occupation: selectedData?.occupation,
  };

  const SubmitHandler = (values) => {
    if (!values.name) {
      toast.error("Please enter your Name");
      return;
    }
    editBedsMutate(values);
  };
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Beds</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik
          initialValues={InitialValues}
          onSubmit={SubmitHandler}
          enableReinitialize
        >
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    className="truncate"
                  />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="mobile">Phone</Label>
                  <FieldWrapper className="bg-gray-100">
                    <Field
                      type="text"
                      name="mobile"
                      id="mobile"
                      disabled
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper $select={true}>
                    <Field
                      required
                      name="status"
                      id="status"
                      autoComplete="off"
                    >
                      {(props) => (
                        <Select className="w-full h-full pl-2" {...props.field}>
                          <MenuItem value="1">Unverified</MenuItem>
                          <MenuItem value="2">Vacant</MenuItem>
                          <MenuItem value="3">Linked</MenuItem>
                          <MenuItem value="4">Requested</MenuItem>
                          <MenuItem value="5">Occupied</MenuItem>
                          <MenuItem value="6">Moving Out</MenuItem>
                          <MenuItem value="7">Reserved</MenuItem>
                        </Select>
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="status">Occupation</Label>
                  <FieldWrapper $select={true}>
                    <Field
                      required
                      name="occupation"
                      id="soccupation"
                      autoComplete="off"
                    >
                      {(props) => (
                        <Select className="w-full h-full pl-2" {...props.field}>
                          <MenuItem value="1">Student</MenuItem>
                          <MenuItem value="2">Professional</MenuItem>
                        </Select>
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gender">Gender</Label>
                  <FieldWrapper $select={true}>
                    <Field
                      required
                      name="gender"
                      id="gender"
                      autoComplete="off"
                    >
                      {(props) => (
                        <Select className="w-full h-full pl-2" {...props.field}>
                          <MenuItem value="1">Male</MenuItem>
                          <MenuItem value="2">Female</MenuItem>
                          {/* <MenuItem value="0">Both</MenuItem> */}
                        </Select>
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <InputGroup>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    className="truncate"
                  />
                </FieldWrapper>
              </InputGroup>
            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`grid gap-6 my-6 px-1 `;
const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditBeds;

import React, { useEffect, useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";
import Navbar from "../../Components/Navbar";
import tw from "tailwind-styled-components";
import { useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading";
import Table from "../../Components/OwnerExtraCharges/Table";
import { AddExtraCharges } from "../../Components/OwnerExtraCharges/AddExtraCharges";
import LinkCharges from "../../Components/OwnerExtraCharges/LinkCharges";
import { toast } from "react-toastify";
import NotFoundModel from "../../Components/NotFoundModel";

const OwnerExtraCharges = () => {
  const { clientId } = useParams();
  const user = useSelector((state) => state.UserReducer.user);
  const [addExtraCharges, setAddExtraCharges] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [extraCharges, setExtraCharges] = useState(null);
  const [linkCharges, setLinkCharges] = useState(null);
  const [apiData, setApiData] = useState();
  const location = useLocation();

  const fetchExtraCharges = async () => {
    const formData = new FormData();
    formData.append("clientId", clientId);

    return await axios.post(`${Config.apiUrl}/getclientcharges`, formData, {
      headers: {
        authorization: "Bearer " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const OnSuccessExtraCharges = (res) => {
    setApiData(res?.data);
  };
  const OnErrorExtraCharges = (res) => {};

  const {
    isLoading: isLoading,
    error,
    data,
    mutate: refetch,
  } = useMutation(fetchExtraCharges, {
    onSuccess: OnSuccessExtraCharges,
    onError: OnErrorExtraCharges,
  });

  useEffect(() => {
    refetch();
  }, []);

  const EXTRA_CHARGES_TYPE = [
    "Please Select Charges Type",
    "Electricity",
    "Registration",
    "Tech Charges",
    "Maintenance",
    "Others",
  ];

  const AddTentantFunction = async (values) => {
    values = Object.assign(
      { name: EXTRA_CHARGES_TYPE[values.chargesType] },
      values
    );
    const formData = new FormData();
    const keys = Object.keys(values);

    keys.map((i) => {
      if (i !== "linkedProp") formData.append(i, values[i]);
      else formData.append(i, JSON.stringify(values[i]));
    });

    formData.append("clientId", clientId);

    return await axios.post(`${Config.apiUrl}/addclientcharges`, formData, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const AddExtraChargesSuccess = (data) => {
    setSelectedData("");
    setExtraCharges(false);
    setApiData(data.data);
    toast.success(data?.data?.msg || "Success");
  };

  const AddExtraChargesError = (data) => {
    setSelectedData("");
    setExtraCharges(false);
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: AddExtraChargesLoading, mutate: AddExtraChargesMutate } =
    useMutation(AddTentantFunction, {
      onSuccess: AddExtraChargesSuccess,
      onError: AddExtraChargesError,
    });

  const LinkExtraCharges = async (checkData) => {
    const formData = new FormData();
    formData.append("clientId", clientId);

    const data = [];
    const unlink = [];
    for (let [key, value] of checkData) {
      if (value) {
        data.push({ ChargeId: selectedData.id, PropId: key });
      } else {
        unlink.push({ ChargeId: selectedData.id, PropId: key });
      }
    }

    formData.append("newCharges", JSON.stringify(data));
    formData.append("unlinkNewCharges", JSON.stringify(unlink));

    return axios.post(`${Config.apiUrl}/linkunlinkcharges`, formData, {
      headers: {
        authorization: "Bearer " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const LinkExtraChargesSuccess = (data) => {
    setLinkCharges(false);
    setSelectedData("");
    setApiData(data.data);
    toast.success(data?.data?.msg || "Success");
  };

  const LinkExtraChargesError = (data) => {
    setLinkCharges(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: LinkExtraChargesLoading, mutate: LinkExtraChargesMutate } =
    useMutation(LinkExtraCharges, {
      onSuccess: LinkExtraChargesSuccess,
      onError: LinkExtraChargesError,
    });
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {"Extra Charges "}
                <span className="text-primary-color">
                  ( {location?.state?.name} )
                </span>
                <Underline />
              </Title>
            </div>
            <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setExtraCharges(true)}
            >
              Add Charges
            </button>
          </div>

          {linkCharges && (
            <LinkCharges
              setLinkCharges={setLinkCharges}
              LinkExtraChargesMutate={LinkExtraChargesMutate}
              LinkExtraChargesLoading={LinkExtraChargesLoading}
              selectedData={selectedData}
              propertyList={data?.data?.properties}
            />
          )}

          {extraCharges && (
            <AddExtraCharges
              setAddCharges={setExtraCharges}
              data={apiData?.properties}
              AddExtraChargesLoading={AddExtraChargesLoading}
              AddExtraChargesMutate={AddExtraChargesMutate}
            />
          )}

          <TableWrapper>
            {isLoading && <Loading />}
            {!isLoading && apiData?.data?.msg !== "No data found" && (
              <Table
                ApiData={apiData?.extraCharges}
                userType={user.userType}
                setAddExtraCharges={setAddExtraCharges}
                setSelectedData={setSelectedData}
                isRefetching={refetch}
                setAddCharges={setExtraCharges}
                setLinkCharges={setLinkCharges}
              />
            )}
            {(error || apiData?.msg == "No data found") && !isLoading && (
              <NotFoundModel />
            )}
          </TableWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;
const Title = tw.h1`text-xl text-gray-800 whitespace-nowrap font-medium`;
export default OwnerExtraCharges;

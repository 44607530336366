import React, { useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import { SubmitBtn } from "../Styles/InputStyles";
import { useSelector } from "react-redux";
import Loading from "../Loading";

const EditExtraChargs = ({
  selectedData,
  addExtraCharges,
  setAddExtraCharges,
  mutationData,
  loadingData,
}) => {
  const [charges, setCharges] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [repetation, setRepetation] = useState(
    selectedData?.repetitionType || "1"
  );

  const submitFunction = async () => {
    if (!charges) {
      setError("Please Enter a amount");
    } else if (charges.match(/^[0-9]+$/) === null) {
      setError("Please Enter only numbers");
    } else {
      if (charges) {
        setError(undefined);
        mutationData({ charges, repetation });
      }
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setAddExtraCharges}>
      {loadingData ? (
        <Loading />
      ) : (
        <Wrapper>
          <Title>Edit Charges</Title>
          <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">
            You may Edit Extra Charges for the{" "}
            <span className="text-primary-color">{selectedData?.name}</span>
          </p>
          <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1">
            Amount
          </p>
          <input
            type="text"
            placeholder={`Previous Charge was ${selectedData.amount}₹`}
            className="border rounded text-black"
            value={charges}
            // onChange={(e) => setCharges(e.target.value)}
            onChange={(e) => {
              const { value } = e.target;
              if (/^\d{0,10}$/.test(value)) {
                console.log(value);
                setCharges(value);
              }
            }}
          />
          {error && (
            <p className="text-sm md:text-lg lg:text-base text-rose-400">
              {error}
            </p>
          )}
          <p className="text-xs md:text-sm lg:text-base text-gray-600 mt-3 mb-1">
            Repetition
          </p>
          <select
            value={repetation}
            onChange={(e) => setRepetation(e.target.value)}
            className="border"
          >
            <option
              value="2"
              disabled={selectedData?.name === "Registration" ? true : false}
            >
              Monthly
            </option>
            <option value="1">Once</option>
          </select>
          <BtnWrapper onClick={() => submitFunction()}>
            <SubmitBtn>Submit</SubmitBtn>
          </BtnWrapper>
        </Wrapper>
      )}
    </Model>
  );
};
const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;

export default EditExtraChargs;

import React, { useEffect, useState } from "react";
import { FaLink } from "react-icons/fa6";
import { IoMdMore } from "react-icons/io";
import { usePagination, useSortBy, useTable } from "react-table";
import { Tooltip } from "react-tooltip";
import tw from "tailwind-styled-components";
import Config, { PaginationLimit } from "../../Config";
import Images from "../../Images";
import { NextBtn, PaginationWrapper, PrevBtn } from "../Styles/PageStyles";

const Table = ({ ApiData, setSelectedData, userType, setEditData,setLinkBank }) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [previousId, setPreviousId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [openActionMenu, setOpenActionMenu] = useState(null);

  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const edit = (item) => {
    setEditData(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const ActionBtns = (item) => {
    const openMenu = (id) => {
      setPreviousId(openActionMenu);
      setOpenActionMenu((prevId) => {
        return prevId === id ? null : id;
      });
    };

    return (
      <ActionBtnWrapper>
        {openActionMenu === item.id && (
          <MenuOptions id="menuOptions">
            {userType != Config.userType.MARKETING && (
              <MenuItem
              onClick={() => {
                setLinkBank(true)
                setSelectedData(item);
                setOpenActionMenu(null)
              }}
            >
              <FaLink className="text-gray-700 " /> Link - Unlink
            </MenuItem>
            )}
          </MenuOptions>
        )}
        <Btn title="Actions" type="button" onClick={() => openMenu(item.id)}>
          <IoMdMore className="text-gray-600" size={24} />
        </Btn>
      </ActionBtnWrapper>
    );
  };


  function BankOrUpi(data) {
    return <div className="font-semibold">{data}</div>;
  }

  function getAccNum(data) {
    return <ID># {data}</ID>;
  }

  function getIFSC(data) {
    return <IFSC>{data}</IFSC>;
  }

  function truncate(str) {
    return str.length > 15 ? str?.toString()?.substring(0, 12) + "..." : str;
  }

  function getBankAdd(data) {
    return (
      <BankAdd
        data-tooltip-id="bank-tooltip"
        data-tooltip-content={data}
        className="cursor-pointer"
        data-tooltip-variant="info"
        data-tooltip-delay-show={500}
      >
        <div>{truncate(data)}</div>
        <Tooltip
          id="bank-tooltip"
          style={{
            backgroundColor:'rgba(58,159,191,0.8)',
          }}
        />
      </BankAdd>
    );
  }

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        bank: BankOrUpi(
          (item.type === 1 ? item?.bankName : item?.upiId) || "--/--"
        ),
        account: item?.accountNum ? getAccNum(item?.accountNum) : "--/--",
        ifsc: item?.ifsc ? getIFSC(item?.ifsc) : "--/--",
        bankAddress: item?.bankAddress
          ? getBankAdd(item?.bankAddress)
          : "--/--",
        holderName:item.holderName? <DataName>{item?.holderName}</DataName>: "--/--",
        action: ActionBtns(item),
      }));
      setData(tempData);
    }
  }, [ApiData, openActionMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#menuOptions") &&
        !event.target.closest(".action-btn")
      ) {
        setOpenActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Bank/UPI",
          accessor: "bank",
        },
        {
          Header: "Name",
          accessor: "holderName",
        },
      ];
    }

    // Columns for larger screens
    return [
      
      {
        Header: "Bank/UPI",
        accessor: "bank",
      },
      {
        Header: "Name",
        accessor: "holderName",
      },
      {
        Header: "Acc Number",
        accessor: "account",
      },
      {
        Header: "IFSC",
        accessor: "ifsc",
      },
      {
        Header: "Bank Address",
        accessor: "bankAddress",
      },
      
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: currentPage, pageSize: PaginationLimit },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setCurrentPage(pageIndex); // Update current page on state change
  }, [pageIndex]);
  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${
                          column.isSortedDesc ? "-rotate-90" : "rotate-90"
                        } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()} hasData={ApiData?.length > 0}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            cellIndex === 0 ||
                            (cellIndex === 1 && window.innerWidth < 768)
                          ) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul className="flex flex-col gap-2">
                        <li>
                          <strong>City:</strong> {data[rowIndex].city}
                        </li>
                        <li>
                          <strong>Created At:</strong> {data[rowIndex].date}
                        </li>
                        <li className="flex gap-2">
                          <strong>Status:</strong> {data[rowIndex].status}
                        </li>
                        <li>
                          <strong>Action:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>
          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75 "
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};
const Btn = tw.button`action-btn grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ActionBtnWrapper = tw.div`flex items-center gap-3 relative `;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? "border-b" : "")}`;
const Tr = tw.tr`${(props) =>
  props.hasData
    ? "border-b"
    : ""} rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;
const MenuOptions = tw.div`absolute bottom-10 -left-16 right-0 py-4 w-max bg-white z-50 rounded-lg shadow-lg border border-gray-200 flex flex-col`;
const MenuItem = tw.div`flex items-center gap-2 px-4 py-2 text-base font-medium cursor-pointer hover:bg-gray-100`;
const ID = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border max-w-fit px-2 h-7 flex items-center justify-center rounded`;
const IFSC = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const BankAdd = tw.div`bg-orange-100 text-orange-700 text-xs border-orange-300 border w-24 h-7 flex items-center justify-center rounded`;
const DataName = tw.div`bg-purple-100 text-purple-700 text-xs border-purple-300 border w-24 h-7 flex items-center justify-center rounded`;

export default Table;

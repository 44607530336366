import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import Navbar from "../../Components/Navbar";
import Loading from "../../Components/Loading";
import NotFoundModel from "../../Components/NotFoundModel";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";
import Config from "../../Config";
import Tables from "../../Components/Notifications/Tables";

export const Notifications = () => {
  const user = useSelector((state) => state.UserReducer.user);

  const defaultData = [
    { id: 1, category: "WhatsApp", enabled: "1" },
    { id: 2, category: "SMS", enabled: "0" },
  ];

  const [apiData, setApiData] = useState(defaultData);
  const [error, setError] = useState(false);

  const fetchData = async () => {
    const body = new FormData();
    return await axios.post(`${Config.apiUrl}/getnotificationpref`, body, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onDataSuccess = (res) => {
    if (res.data.status === "NOK" || !res.data.data) {
      setError(true);
    } else {
      setApiData(res.data.data);
    }
  };

  const onDataError = () => {
    setError(true);
  };

  const { isLoading: isDataLoading, mutate: DataMutate } = useMutation(
    fetchData,
    {
      onSuccess: onDataSuccess,
      onError: onDataError,
    }
  );

  useEffect(() => {
    DataMutate();
  }, []);

  return (
    <Bg
      style={{
        backgroundImage: `url(${Images.BeamsBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "top left",
        height: "100vh",
      }}
    >
      <Navbar />
      <BoxContainer>
        <div className="flex w-full justify-between items-center">
          <div>
            <Title>Notifications Categories</Title>
            <Underline />
          </div>
        </div>
        <TableWrapper>
          <Tables data={apiData} />
          {isDataLoading && <Loading />}
          {error && !isDataLoading && <NotFoundModel />}
        </TableWrapper>
      </BoxContainer>
    </Bg>
  );
};

const Title = tw.h1`text-xl w-full text-gray-800 whitespace-nowrap font-medium flex justify-center`;
const TableWrapper = tw.div`my-10 border bg-white rounded-md p-4`;

export default Notifications;

import React, { useEffect, useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";
import Navbar from "../../Components/Navbar";
import { useLocation, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import { useSelector } from "react-redux";
import axios from "axios";
import { useMutation } from "react-query";
import Tables from "../../Components/Staff/Tables";
import EditStaff from "../../Components/Staff/EditStaff";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Loading from "../../Components/Loading";
import AddStaff from "../../Components/Staff/AddStaff";
import LinkStaff from "../../Components/Staff/LinkStaff";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Form, Formik, Field } from "formik";
import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";
import { toast } from "react-toastify";
import NotFoundModel from "../../Components/NotFoundModel";

const Staff = () => {
  const location = useLocation();
  const { clientId } = useParams();
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState();
  const [editStaff, setEditStaff] = useState(false);
  const [linkStaff, setLinkStaff] = useState(false);
  const [addStaff, setAddStaff] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [staffData, setStaffData] = useState();

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    body.append("startDate", startDate);
    body.append("endDate", endDate);
    body.append("clientId", clientId);
    return await axios.post(`${Config.apiUrl}/getstaffs`, body, {
      headers: {
        authorization: "Bearer " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getLinkSuccess = (res) => {
    console.log(res)
    setStaffData(res.data);
  };

  const getLinkError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getLinkMutate,
    error,
    data,
    isRefetching,
    isSuccess,
  } = useMutation(fetchFunction, {
    onSuccess: getLinkSuccess,
    onError: getLinkError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getLinkMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue, startDate, endDate]);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    getLinkMutate();
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };

  //Staff Edit Function
  const EditStaffFunction = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    formData.append("id", selectedData.id);
    formData.append("clientId", clientId);

    return await axios.post(`${Config.apiUrl}/editstaff`, formData, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  //Staff Edit Success
  const onEditStaffSuccess = async (res) => {
    if (res?.data?.status === "OK") {
      toast.success(res?.data?.msg || "Success");
      
      setStaffData (res?.data);
    } else {
      toast.error(res?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditStaff(false);
  };
  //Staff Edit Error
  const onEditStaffError = async (data) => {
    setEditStaff(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  //staff Edit Mutation
  const { isLoading: EditStaffLoading, mutate: editStaffMutate } = useMutation(
    EditStaffFunction,
    {
      onSuccess: onEditStaffSuccess,
      onError: onEditStaffError,
    }
  );
  //Add staff Function
  const AddStaffFunction = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    formData.append("id", clientId);
    return await axios.post(`${Config.apiUrl}/addstaff`, formData, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddStaffSuccess = (data) => {
    if (data?.data?.status === "OK") {
      toast.success(data?.data?.msg || "Success");
      console.log(data?.data);
      
      setStaffData(data.data)
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setAddStaff(false);
  };

  const onAddStaffError = (data) => {
    setAddStaff(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: AddStaffLoading, mutate: AddStaffMutate } = useMutation(
    AddStaffFunction,
    {
      onSuccess: onAddStaffSuccess,
      onError: onAddStaffError,
    }
  );

  const LinkStaffFunction = async (checkData) => {
    const formData = new FormData();
    formData.append("clientId", clientId);

    const linkedStaff = [];
    const unlinkedStaff = [];
    checkData.forEach((isChecked, propId) => {
      const staffInfo = { StaffId: selectedData.id, PropId: propId };
      isChecked ? linkedStaff.push(staffInfo) : unlinkedStaff.push(staffInfo);
    });

    formData.append("newStaff", JSON.stringify(linkedStaff));
    formData.append("unlinkNewStaff", JSON.stringify(unlinkedStaff));

    return await axios.post(`${Config.apiUrl}/linkunlinkstaffs`, formData, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const LinkStaffOnSuccess = (data) => {
    if (data?.data?.status === "OK") {
      toast.success(data?.data?.msg || "Success");
      
      setStaffData(data.data)
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setLinkStaff(false);
    setSelectedData("");
  };

  const LinkStaffOnError = (data) => {
    setLinkStaff(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: LinkStaffLoading, mutate: LinkStaffMutate } = useMutation(
    LinkStaffFunction,
    {
      onSuccess: LinkStaffOnSuccess,
      onError: LinkStaffOnError,
    }
  );

  console.log(staffData)
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {"Staff "}
                <span className="text-primary-color">
                  ( {location?.state?.name} )
                </span>
                <Underline />
              </Title>
            </div>
            <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setAddStaff(true)}
            >
              Add Staff
            </button>
          </div>
          <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              ChangeHandler={ChangeHandler}
              filterValue={filterValue}
              filter={filter}
              user={user}
              t={t}
            />
          </div>
          {editStaff && (
            <EditStaff
              editStaffMutate={editStaffMutate}
              editStaffLoading={EditStaffLoading}
              setEditStaff={setEditStaff}
              data={data?.data}
              selectedData={selectedData}
            />
          )}
          {addStaff && (
            <AddStaff
              addStaffMutate={AddStaffMutate}
              addStaffLoading={AddStaffLoading}
              setAddStaff={setAddStaff}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {linkStaff && (
            <LinkStaff
              LinkStaffMutate={LinkStaffMutate}
              LinkStaffLoading={LinkStaffLoading}
              setLinkCharges={setLinkStaff}
              selectedData={selectedData}
              propertyList={data?.data?.properties}
            />
          )}

          <TableWrapper>
            {isLoading && <Loading />}
            {isSuccess &&
              !isRefetching &&
              staffData?.msg !== "No data found" && (
                <Tables
                  ApiData={staffData.staffs}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setEditStaff={setEditStaff}
                  setLinkStaff={setLinkStaff}
                  refetch={getLinkMutate}
                />
              )}
            {(error || staffData
            ?.msg == "No data found") && !isLoading && (
              <NotFoundModel />
            )}
          </TableWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  setFilterValue,
  filterValue,
  ChangeHandler,
  t,
}) => {
  const today = moment().format("YYYY-MM-DD");
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <div className="flex gap-2 items-end sm:text-base text-xs flex-wrap">
            <div className="flex items-end gap-2">
              <InputGroup className="max-w-[130px]">
                <Label htmlFor="startDate">{t("Start_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="startDate"
                    id="startDate"
                    class="ml-1"
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup className="max-w-[130px]">
                <Label htmlFor="endDate">{t("End_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="endDate"
                    id="endDate"
                    class="ml-1"
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>
            </div>
            <ApplyBtn disabled={InitialLoading} type="submit">
              {t("Date_filter")}
            </ApplyBtn>
          </div>

          <div className="flex gap-4 items-end">
            {filter !== "status" && filter !== "paystatus" ? (
              <InputGroup className="max-w-[200px]">
                <Label htmlFor="filterVal">{t("")}</Label>
                <Field
                  placeholder="Search..."
                  type="text"
                  name="filterVal"
                  id="filterVal"
                  class="ml-1"
                  value={filterValue}
                  onChange={ChangeHandler}
                  className="border border-slate-300 rounded-md px-4 focus:ring-1 focus:ring-primary-color focus:border-primary-color py-2"
                />
              </InputGroup>
            ) : filter === "status" ? (
              <InputGroup
                className="min-w-[120px]"
                style={{ flexBasis: "content" }}
              >
                <Label htmlFor="filter">{t("Select")}</Label>
                <Select
                  autoComplete="off"
                  className="px-2 bg-transparent text-sm border-none"
                  style={{ height: "40px" }}
                  value={filterValue}
                  onChange={ChangeHandler}
                >
                  <MenuItem value="" disabled>
                    {t("Select Status")}
                  </MenuItem>
                  <MenuItem value="3">{t("Active")}</MenuItem>
                  <MenuItem value="4">{t("Enrolled")}</MenuItem>
                </Select>
              </InputGroup>
            ) : null}

            <InputGroup
              className="min-w-[120px]"
              style={{ flexBasis: "content" }}
            >
              <Label htmlFor="filter">{t("Search By")}</Label>

              <Select
                autoComplete="off"
                className="w-24 px-2 bg-transparent text-sm border-none"
                style={{ height: "40px" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setFilterValue("");
                }}
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="mobile">Phone</MenuItem>
              </Select>
            </InputGroup>
          </div>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-primary-color hover:bg-hover-color  py-2.5 text-sm`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;

export default Staff;

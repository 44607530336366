import { IoBedOutline } from "react-icons/io5";

const BedIcon = ({
  id,
  setSelectedBed,
  selectedBed,
  occupied,
  data,
  setSelectedBedData,
  selectedBedData,
}) => {
  
  return (
    <div
      className={`p-5 cursor-pointer border rounded flex-row justify-center items-center text-center ${
        selectedBed === id || data.status==3
          ? "border-primary-color border-2 drop-shadow-lg shadow-lg"
          : ""
      } ${data.status==3?"grayscale opacity-50 hover:cursor-not-allowed":""}`}
      onClick={() => {
        if(data.status!=3){
          setSelectedBed(id);
          setSelectedBedData(data)
        }
      }}
    
    >
      <span className="text-center flex justify-center items-center w-full">
        <IoBedOutline
          className={`text-5xl text-center ${
            selectedBed === id ? "text-primary-color" : ""
          } `}
        />
      </span>
      {id >= 0 ? (
        <p>
          Bed ID <span className="text-primary-color">{id}</span>
        </p>
      ) : (
        <p>
          <span className="text-primary-color">Occupy All Beds</span>
        </p>
      )}
    </div>
  );
};

export default BedIcon;

import React, { useEffect, useState } from "react";
import { useSortBy, useTable } from "react-table";
import tw from "tailwind-styled-components";

const Table = ({ ApiData }) => {
  const [data, setData] = useState([
    {
      category: "",
      branded: "",
    },
  ]);

  useEffect(() => {
    if (ApiData && ApiData.length > 0) {
      const formattedData = ApiData.map((item) => ({
        category: item.category || "N/A",
        branded: item.branded ? "Yes" : "No",
      }));
      setData(formattedData);
    }
  }, [ApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Branded",
        accessor: "branded",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                </Tr>
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
    </>
  );
};

const CustomTable = tw.table`w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;

export default Table;

import React, { useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loading from "../Loading";

const AddFloor = ({
  setViewAddFloors,
  selectedData,
  AddFloorMutate,
  AddFloorLoading
}) => {
  const InitialValue = {
    floorCount: "",
  };

  const addFloorSchema = Yup.object().shape({
    floorCount: Yup.string().required("Enter the floor count"),
  });
  const addFloorFormik = useFormik({
    initialValues: InitialValue,
    validationSchema: addFloorSchema,
    onSubmit: (values) => {
      AddFloorMutate(values);
    },
  });
  
  return (
    <Model
      width={`w-11/12 max-w-lg`}
      setOpenModel={setViewAddFloors}
    >
      {AddFloorLoading ? (
        <Loading />
      ) : (
        <Wrapper>
          <Title>Add Floors</Title>
          <h2 className="text-sm md:text-lg lg:text-lg text-gray-600 font-medium ">
            Number of Floors
          </h2>
          <p className="text-xs md:text-sm lg:text-base text-gray-600 ">
            Set number of floors to be increased in the property
          </p>
          <form onSubmit={addFloorFormik.handleSubmit}>
            <div className="h-10 w-40 border border-gray-300 rounded-md">
              <input
                type="number"
                name="floorCount"
                value={addFloorFormik.values.floorCount}
                onChange={addFloorFormik.handleChange}
                onBlur={addFloorFormik.handleBlur}
              />
            </div>
            <div className="text-rose-500">
              {addFloorFormik.touched.floorCount &&
                addFloorFormik.errors.floorCount}
            </div>
            <BtnWrapper>
              <input
                type="submit"
                value="Submit"
                className="text-sm  w-32  md:w-44 h-12 grid place-items-center md:text-base bg-primary-color hover:bg-hover-color text-white rounded-md shadow-md`;"
              />
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};
const Wrapper = tw.div`
grid gap-2 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-4`;

export default AddFloor;

import React from "react";
import tw from "tailwind-styled-components";
import { useFormik } from "formik";
import Loading from "../Loading";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import * as Yup from "yup";
import { toast } from "react-toastify";

const EditOwnerModel = ({
  editOwnerMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const SubmitHandler = (values) => {
    if (!values.name) {
      toast.error("Please enter your Name");
      return;
    }
    editOwnerMutate(values);
  };

  const formik = useFormik({
    initialValues: {
      name: selectedData?.name || "",
      mobile: selectedData?.mobile || "",
      status: selectedData?.status || "",
      id: selectedData?.id || "",
      panNumber: selectedData?.panNumber || "",
      isBankDetailsShown: selectedData?.isBankDetailsShown || 0,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      mobile: Yup.string()
        .matches(/^\d{10}$/, "Enter a valid number")
        .required("Mobile number is required"),
      status: Yup.number().required("Select Status"),
      id: Yup.number().required("ID is required"),
      panNumber: Yup.string().matches(
        /^[A-Z0-9]{10}$/,
        "PAN must be 10 uppercase alphanumeric characters"
      ),
      isBankDetailsShown: Yup.number().required("Select an option"),
    }),
    onSubmit: SubmitHandler,
    enableReinitialize: true,
  });

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      {editEntryLoading ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Edit Owner Details</Title>
          <form onSubmit={formik.handleSubmit}>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="name">Name</Label>
                  <FieldWrapper>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.name}
                      </p>
                    )}
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="email">Email</Label>
                  <FieldWrapper>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="off"
                      className="truncate"
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="mobile">Phone</Label>
                  <FieldWrapper>
                    <Input
                      type="text"
                      name="mobile"
                      id="mobile"
                      className="bg-gray-100"
                      autoComplete="off"
                      required
                      disabled
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.mobile}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper $select={true}>
                    <Select
                      name="status"
                      id="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      className="w-full pl-2"
                      displayEmpty
                    >
                      <MenuItem value="1">Unverified</MenuItem>
                      <MenuItem value="2">Verified</MenuItem>
                      <MenuItem value="3">Active</MenuItem>
                      <MenuItem value="4">Enrolled</MenuItem>
                    </Select>
                    {formik.touched.status && formik.errors.status && (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.status}
                      </p>
                    )}
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="panNumber">PAN Number</Label>
                  <FieldWrapper>
                    <Input
                      type="text"
                      name="panNumber"
                      id="panNumber"
                      autoComplete="off"
                      value={formik.values.panNumber}
                      onChange={(e) => {
                        const value = e.target.value.toUpperCase();
                        if (/^[A-Z0-9]{0,10}$/.test(value)) {
                          formik.setFieldValue("panNumber", value);
                        }
                      }}
                    />
                    {/* {formik.touched.panNumber && formik.errors.panNumber && (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.panNumber}
                      </p>
                    )} */}
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="isBankDetailsShown">Bank Details Shown</Label>
                  <FieldWrapper $select={true}>
                    <Select
                      name="isBankDetailsShown"
                      id="isBankDetailsShown"
                      value={formik.values.isBankDetailsShown}
                      onChange={formik.handleChange}
                      className="w-full pl-2"
                      displayEmpty
                    >
                      <MenuItem value="0">No</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                    </Select>
                    {formik.touched.isBankDetailsShown &&
                      formik.errors.isBankDetailsShown && (
                        <p className="text-rose-600 text-sm">
                          {formik.errors.isBankDetailsShown}
                        </p>
                      )}
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};

const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Wrapper = tw.div`px-1  space-y-4`;
const FieldWrapper = tw.div`w-full`;
const CombineInputGroup = tw.div`flex space-x-7`;
const Input = tw.input`truncate border border-gray-300 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default EditOwnerModel;

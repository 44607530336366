import React from "react";
import tw from "tailwind-styled-components";
import { useFormik } from "formik";
import Loading from "../Loading";
import * as Yup from "yup";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Model from "../Model";
import moment from "moment";

const EditOccupancyModel = ({
  editOccupancyMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const today = moment().format("YYYY-MM-DD");

  const formik = useFormik({
    initialValues: {
      id: selectedData?.id,
      tenantId: selectedData?.tenantId,
      rent: selectedData?.rent || "",
      security: selectedData?.security || "",
      moveOutDate: selectedData?.moveOutDate || "",
      status: selectedData?.status || "",
      kycstatus: selectedData?.tenantKycStatus || "",
      lockInPeriod: selectedData?.lockInPeriod || "",
      noticePeriod: selectedData?.noticePeriod || "",
      moveInDate: moment(selectedData?.moveInDate).format("YYYY-MM-DD"),
      agreementStartDate: moment(selectedData?.agreementStartDate).format(
        "YYYY-MM-DD"
      ),
    },
    validationSchema: Yup.object().shape({
      rent: Yup.number().required("Rent is required"),
      security: Yup.number().required("Security is required"),
      status: Yup.string().required("Select status"),
      kycstatus: Yup.string().required("Select KYC status"),
      lockInPeriod: Yup.number()
        .required("Lock in period is required")
        .min(0, "Must be at least 0")
        .max(12, "Cannot be greater than 12"),
      noticePeriod: Yup.number()
        .required("Notice period is required")
        .min(0, "Must be at least 0")
        .max(12, "Cannot be greater than 12"),
      moveInDate: Yup.date().required("Move in date is required"),
    }),
    onSubmit: (values) => {
      if (formik.values.status !== "5") {
        delete values.moveOutDate;
      }
      const updatedValues = {
        ...values,
        agreementStartDate: values.moveInDate,
      };
      editOccupancyMutate(updatedValues);
    },
    enableReinitialize: true,
  });

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Occupancy</Title>
      {editEntryLoading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Wrapper>
            <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="rent">Rent</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="rent"
                    id="rent"
                    value={formik.values.rent}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    required
                  />
                  {formik.touched.rent && formik.errors.rent && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.rent}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="security">Security</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="security"
                    id="security"
                    value={formik.values.security}
                    onChange={formik.handleChange}
                    required
                  />
                  {formik.touched.security && formik.errors.security && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.security}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
            </CombineInputGroup>
            <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="status">Status</Label>
                <FieldWrapper $select={true}>
                  <Select
                    name="status"
                    id="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    required
                    className="w-full pl-2"
                  >
                    <MenuItem value="1">Pending</MenuItem>
                    <MenuItem value="2">Requested</MenuItem>
                    <MenuItem value="3">Occupied</MenuItem>
                    <MenuItem value="4">Reserved</MenuItem>
                    <MenuItem value="5">Moving Out</MenuItem>
                  </Select>
                  {formik.touched.status && formik.errors.status && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.status}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="kycstatus">KYC Status</Label>
                <FieldWrapper $select={true}>
                  <Select
                    name="kycstatus"
                    id="kycstatus"
                    value={formik.values.kycstatus}
                    onChange={formik.handleChange}
                    required
                    className="w-full pl-2"
                  >
                    <MenuItem value="1">Pending</MenuItem>
                    <MenuItem value="2">ID Upload</MenuItem>
                    <MenuItem value="3">Personal Information</MenuItem>
                    <MenuItem value="4">Selfie Upload</MenuItem>
                    <MenuItem value="5">Rent Agreement</MenuItem>
                    <MenuItem value="6">Police Verification</MenuItem>
                  </Select>
                  {formik.touched.kycstatus && formik.errors.kycstatus && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.kycstatus}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
            </CombineInputGroup>
            <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="lockInPeriod">Lock In Period</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="lockInPeriod"
                    id="lockInPeriod"
                    autoComplete="off"
                    variant="outlined"
                    value={formik.values.lockInPeriod}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (
                        /^\d{0,2}$/.test(value) &&
                        (value === "" ||
                          (Number(value) >= 0 && Number(value) <= 12))
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                  />
                  {formik.touched.lockInPeriod && formik.errors.lockInPeriod ? (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.lockInPeriod}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="noticePeriod">Notice Period</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="noticePeriod"
                    id="noticePeriod"
                    autoComplete="off"
                    variant="outlined"
                    value={formik.values.noticePeriod}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (
                        /^\d{0,2}$/.test(value) &&
                        (value === "" ||
                          (Number(value) >= 0 && Number(value) <= 12))
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                  />
                  {formik.touched.noticePeriod && formik.errors.noticePeriod ? (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.noticePeriod}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>
            </CombineInputGroup>
            <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="moveInDate">Move In Date</Label>
                <FieldWrapper>
                  <CalendarMonthIcon className="absolute left-1 top-2 text-gray-400" />
                  <Input
                    type="date"
                    name="moveInDate"
                    id="moveInDate"
                    className="pl-8"
                    value={formik.values.moveInDate}
                    onChange={formik.handleChange}
                    required
                  />
                  {formik.touched.moveInDate && formik.errors.moveInDate && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.moveInDate}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
            </CombineInputGroup>
            {formik.values.status === "5" && (
              <InputGroup>
                <Label htmlFor="moveout date">Moveout Date</Label>
                <FieldWrapper className="bg-gray-100">
                  <Input
                    type="date"
                    name="moveOutDate"
                    id="moveOutDate"
                    value={formik.values.moveOutDate}
                    onChange={formik.handleChange}
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>
            )}
          </Wrapper>
          <BtnWrapper>
            <SubmitBtn type="submit">Update</SubmitBtn>
          </BtnWrapper>
        </form>
      )}
    </Model>
  );
};

const Wrapper = tw.div`grid gap-6 my-6 px-1`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const FieldWrapper = tw.div`w-full relative`;
const CombineInputGroup = tw.div`flex space-x-7`;
const Input = tw.input`truncate border border-gray-300 rounded pl-2`;

export default EditOccupancyModel;
